import { observer } from 'mobx-react';
import { useEffect, useState, lazy, useMemo } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './i18n/config';
import { LicenseInfo } from '@mui/x-license-pro';
import '@/lib/yup';
import './App.scss';
import RequireAuth from './Providers/AuthProvider/RequireAuth';
import { useDataStore } from './Providers/StoreProvider';
import { EAdministrationRoute, EMultipassRoute, EStoplistRoute, ROUTES } from './types';
import { MenuDrawer } from '@/components/MenuDrawer';
import rootApiService from '@/services/root.api.service';
import { useSnackbar } from 'notistack';
import { RequirePermission } from './Providers/PermissionProvider/RequirePermission';
import { AppLoader } from './components/loaders/AppLoader';
import { useInitAppSse } from './hooks/useInitAppSse';
import { useUserPermissionsContext } from './hooks/useUserPermissionsContext';
import { useTranslation } from 'react-i18next';
import AuthPage from './pages/AuthPage';

const processError = (error: unknown) => {
  console.error(error);

  return { default: () => <AuthPage /> };
};

const Multipass = lazy(() =>
  import('./modules').then((module) => ({ default: module.Multipass })).catch(processError)
);
const CardCreation = lazy(() =>
  import('./modules/multipass').then((module) => ({ default: module.CardCreation }))
);
const FilingCabinet = lazy(() =>
  import('./modules/multipass').then((module) => ({ default: module.FilingCabinet }))
);

const Administration = lazy(() =>
  import('./modules').then((module) => ({ default: module.Administration })).catch(processError)
);
const Users = lazy(() =>
  import('./modules/administration').then((module) => ({ default: module.Users }))
);
const Roles = lazy(() =>
  import('./modules/administration').then((module) => ({ default: module.Roles }))
);
const Groups = lazy(() =>
  import('./modules/administration').then((module) => ({ default: module.Groups }))
);

const Devices = lazy(() =>
  import('./modules/devices').then((module) => ({ default: module.Devices })).catch(processError)
);

const Stoplist = lazy(() =>
  import('./modules').then((module) => ({ default: module.Stoplist })).catch(processError)
);
const StoplistRecord = lazy(() =>
  import('./modules/stoplist').then((module) => ({ default: module.StoplistRecord }))
);

LicenseInfo.setLicenseKey(
  '9603c734009c51dd5602b3210062b735Tz03OTEyOSxFPTE3MzIxODMzODEwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
);

const App = () => {
  const {
    userStore,
    constantsStore: { init, factorVersion, initConstFactorVersion },
    rootMediaDevicesStore,
    sseStore,
  } = useDataStore();
  const { defaultRoute } = useUserPermissionsContext();
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    init(enqueueSnackbar);
  }, [init, enqueueSnackbar]);

  useEffect(() => {
    if (!factorVersion) {
      void initConstFactorVersion();
    }
  }, [factorVersion, initConstFactorVersion]);

  useEffect(() => {
    const initializeApp = () => {
      try {
        rootApiService.init(userStore, rootMediaDevicesStore, sseStore);
      } catch (error) {
        console.error(error);
      } finally {
        setIsInitialized(true);
      }
    };

    void initializeApp();
  }, [userStore, rootMediaDevicesStore, sseStore]);

  useEffect(() => {
    document.title = t('appName');
  }, [t]);

  useInitAppSse();

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <Route>
            <Route path={ROUTES.AUTH} element={<AuthPage />} />

            <Route
              path={ROUTES.ADMINISTRATION}
              element={
                <MenuDrawer>
                  <RequireAuth>
                    <RequirePermission>
                      <Administration />
                    </RequirePermission>
                  </RequireAuth>
                </MenuDrawer>
              }
            >
              <Route
                path=''
                element={<Navigate to={ROUTES.ADMINISTRATION_USERS} replace={true} />}
              />
              <Route path={EAdministrationRoute.users} element={<Users />} />
              <Route path={EAdministrationRoute.roles} element={<Roles />} />
              <Route path={EAdministrationRoute.groups} element={<Groups />} />
            </Route>

            <Route
              path={ROUTES.MULTIPASS}
              element={
                <MenuDrawer>
                  <RequireAuth>
                    <RequirePermission>
                      <Multipass />
                    </RequirePermission>
                  </RequireAuth>
                </MenuDrawer>
              }
            >
              {/* <Route path='' element={<Navigate to={ROUTES.MULTIPASS_LOAD_FILE} replace={true} />} />
          <Route
            path={EMultipassRoute.loadFile}
            element={
              <RequirePermission>
                <LoadFile />
              </RequirePermission>
            }
          />
          <Route
            path={EMultipassRoute.interactiveCard}
            element={
              <RequirePermission>
                <InteractiveCard />
              </RequirePermission>
            }
          /> */}
              <Route
                path=''
                element={<Navigate to={ROUTES.MULTIPASS_FILLING_CABINET} replace={true} />}
              />
              <Route path={EMultipassRoute.filingCabinet} element={<FilingCabinet />} />
              <Route path={EMultipassRoute.cardCreation} element={<CardCreation />} />
              <Route path={EMultipassRoute.cardEdit} element={<CardCreation />} />
            </Route>

            <Route
              path={ROUTES.STOPLIST}
              element={
                <MenuDrawer>
                  <RequireAuth>
                    <RequirePermission>
                      <Outlet />
                    </RequirePermission>
                  </RequireAuth>
                </MenuDrawer>
              }
            >
              <Route path={''} element={<Stoplist />} />
              <Route path={EStoplistRoute.recordEdit} element={<StoplistRecord />} />
            </Route>

            <Route
              path={ROUTES.DEVICES}
              element={
                <MenuDrawer>
                  <RequireAuth>
                    <RequirePermission>
                      <Devices />
                    </RequirePermission>
                  </RequireAuth>
                </MenuDrawer>
              }
            />
            <Route
              path='*'
              element={
                <RequireAuth>
                  <Navigate to={defaultRoute || ROUTES.AUTH} replace={true} />
                </RequireAuth>
              }
            />
          </Route>
        )
      ),
    [defaultRoute]
  );

  if (!isInitialized) {
    return <AppLoader />;
  }

  return (
    <div className='App'>
      <RouterProvider router={router} />
    </div>
  );
};

export default observer(App);
