import { FC, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType, Header, Icon, icons, IconSize, Separator, Tab } from 'stc-ui-kit';
import { ROUTES } from '@/types';
import { useDataStore } from '@/Providers/StoreProvider';
import userService from '@/services/user.service';
import { filesStore } from '@/modules/multipass/stores/Files';
import AboutModal from '@/components/AboutModal';
import { observer } from 'mobx-react';
import { QrCodeButton } from './QrCodeButton';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { Stack, Typography } from '@mui/material';
import { LanguageSelector } from '../LanguageSelector';
import { appColors } from '@/Providers/ThemeProvider/colors';

export const TopMenu: FC = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userStore, rootMediaDevicesStore: mediaDevicesStore } = useDataStore();
  const { clear } = filesStore;
  const { userData, logoutAndClearUserData } = userStore;
  const { clearStorageRecordingState } = mediaDevicesStore;
  const { checkIsPageAllowed, isCardTokenSession } = useUserPermissionsContext();

  const menuTabs: (Tab & { route: string })[] = useMemo(
    () => [
      {
        id: 'enrollment',
        title: t('enrollment'),
        path: [
          ROUTES.MULTIPASS_FILLING_CABINET,
          ROUTES.MULTIPASS_LOAD_FILE,
          ROUTES.MULTIPASS_INTERACTIVE_CARD,
          ROUTES.MULTIPASS_CARD_CREATION,
          ROUTES.MULTIPASS_CARD_EDIT,
        ].find((r) => checkIsPageAllowed(r)),
        route: ROUTES.MULTIPASS,
      },
      {
        id: 'administration',
        title: t('administration'),
        path: [
          ROUTES.ADMINISTRATION_USERS,
          ROUTES.ADMINISTRATION_GROUPS,
          ROUTES.ADMINISTRATION_ROLES,
        ].find((r) => checkIsPageAllowed(r)),
        route: ROUTES.ADMINISTRATION,
      },
      {
        id: 'devices',
        title: t('devices.devices'),
        path: checkIsPageAllowed(ROUTES.DEVICES) ? ROUTES.DEVICES : undefined,
        route: ROUTES.DEVICES,
      },
      {
        id: 'stoplist',
        title: t('stoplist.stoplist'),
        path: checkIsPageAllowed(ROUTES.STOPLIST) ? ROUTES.STOPLIST : undefined,
        route: ROUTES.STOPLIST,
      },
    ],
    [checkIsPageAllowed, t]
  );

  const activeTabId =
    menuTabs.filter((tab) => pathname.includes(tab.route))[0]?.id ?? menuTabs[0].id;

  const handleLogout = useCallback(async () => {
    clearStorageRecordingState();
    await userService.logOut();
    logoutAndClearUserData();
    clear();
  }, [clear, logoutAndClearUserData, clearStorageRecordingState]);

  const displayedTabs = useMemo(() => menuTabs.filter((tab: Tab) => !!tab.path), [menuTabs]);

  const handleChangeActiveTabId = useCallback(
    (tabId: string) => {
      const navigateTo = displayedTabs.filter((tab) => tab.id === tabId)[0]?.path;
      navigateTo && navigate(navigateTo);
    },
    [displayedTabs, navigate]
  );

  const FullHeader = (
    <Header
      title={t('appName')}
      iconLogo={icons.IconLogoDark}
      tabs={displayedTabs}
      activeTabId={activeTabId}
      onChangeActiveTabId={handleChangeActiveTabId}
      userLogin={userData.username}
      onLogout={handleLogout}
      infoContent={<AboutModal />}
      isFullscreenEnabled={false}
      headerContent={
        <Stack direction={'row'} alignItems={'center'} gap={2}>
          <QrCodeButton />
          <Separator vertical />
          <LanguageSelector />
        </Stack>
      }
    />
  );

  const CardTokenHeader = (
    <Stack
      direction={'row'}
      paddingX={1}
      alignItems={'center'}
      justifyContent={'space-between'}
      width={'100%'}
      height={'56px'}
      borderBottom={`1px solid ${appColors.darkLightGrey}`}
    >
      <Stack direction={'row'} gap={1} alignItems={'center'}>
        <Icon icon={icons.IconLogoDark} iconSize={IconSize.Original} />
        <Typography fontSize={'18px'} fontWeight={700}>
          {t('appName')}
        </Typography>
      </Stack>

      <Button type={ButtonType.Text} onClick={logoutAndClearUserData}>
        {t('auth.signIn')}
      </Button>
    </Stack>
  );

  return !isCardTokenSession ? FullHeader : CardTokenHeader;
});
