import rootApiService from './root.api.service';
import { CallAPIType, METHODS, SnackbarActionType } from '@/types';
import { ApiError } from '@/utils/ApiError';
import { IFileInfoResponse, IFileUploadedResponse } from '@/apiTypes';
import { IFileStorageStructure } from '@/components/FileDialog/types';
import { enqueueSnackbar } from 'notistack';

class FilesApi {
  checkIfExists = async (sha1: string, snackbarAction: SnackbarActionType) => {
    const APIOptions: CallAPIType = {
      path: `file/exists?key=${sha1}`,
      method: METHODS.GET,
      body: null,
    };
    const apiResponse = await rootApiService.callAPI<IFileInfoResponse>(APIOptions);

    if (apiResponse.res && apiResponse.res.key === sha1) {
      return true;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return false;
  };

  uploadFile = async (
    fileName: string,
    sha1: string,
    fileBytes: Uint8Array,
    snackbarAction: SnackbarActionType
  ) => {
    const tosterOptions = {
      snackbarAction,
    };
    const APIOptions: CallAPIType = {
      path: `file/upload?key=${sha1}&name=${fileName}`,
      method: METHODS.POST,
      tosterOptions,
      body: fileBytes,
      headers: new Map<string, string>([['Content-Type', 'application/octet-stream']]),
    };
    const apiResponse = await rootApiService.callAPI<IFileUploadedResponse>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res;
  };

  getFolderStructure = async (path?: string): Promise<IFileStorageStructure | null> => {
    const params = path ? `?path=${encodeURIComponent(path)}` : '';

    const APIOptions: CallAPIType = {
      path: `file/structure${params}`,
      method: METHODS.GET,
      tosterOptions: {
        snackbarAction: enqueueSnackbar,
        message: '',
      },
      body: null,
    };
    const apiResponse = await rootApiService.callAPI<IFileStorageStructure>(APIOptions);

    if (apiResponse.res) {
      return apiResponse.res;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return null;
  };
}

export default new FilesApi();
