import rootApiService from './root.api.service';
import { CallAPIType, METHODS } from '@/types';
import { IFileInfoResponse } from '@/apiTypes';
import { IFileModelView, IMediaData, IModelUpdate } from '@/types/file';
import { enqueueSnackbar } from 'notistack';

class FileModelApi {
  getModelsByFileId = async <T>(fileId: string): Promise<IFileModelView<T>[] | null> => {
    const APIOptions: CallAPIType = {
      path: `processing/file-models/by-file?fileId=${fileId}`,
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI<IFileModelView<T>[]>(APIOptions);

    return apiResponse.res || null;
  };

  getMediaData = async (
    fileModelId: number | string,
    mediaFileId: number
  ): Promise<IMediaData | null> => {
    const APIOptions: CallAPIType = {
      path: `processing/file-models/${fileModelId}/media-data?mediaId=${mediaFileId}`,
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI(APIOptions);

    return apiResponse.res || null;
  };

  updateModel = async (updateStatus: IModelUpdate): Promise<boolean> => {
    const APIOptions: CallAPIType = {
      path: 'processing/file-models/update',
      method: METHODS.POST,
      body: {
        ...updateStatus,
      },
      tosterOptions: { snackbarAction: enqueueSnackbar },
    };
    const apiResponse = await rootApiService.callAPI<IFileInfoResponse>(APIOptions);

    return !!apiResponse.res;
  };
}

export default new FileModelApi();
