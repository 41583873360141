import { APIResponseType, METHODS, ROUTES, IApiFieldFilter, CallAPIType } from '@/types';
import { CardFormData, CreateCardDto, EditCardDto, ICard, ICardsGridItem } from '@/types/card';
import { FaceInteractiveMode, FileGroupType, IFileLink } from '@/types/file';
import { DataProvider } from '@/types/grid';
import { EntityApi } from './entityApi.service';
import rootApiService from './root.api.service';
import { t } from 'i18next';
import { showErrorNotification, showInfoNotification } from '@/utils/notifications';
import { SseMessageType } from '@/types/sse';
import { Predicate } from '@/types/predicate';
import { StatusCodes } from 'http-status-codes';

const cardsApi = new EntityApi('card');

class CardsService {
  dataProvider: DataProvider<ICardsGridItem> = async (state, allCards?: ICardsGridItem[]) => {
    const res = await cardsApi.getFiltersData({
      filters: state,
      localData: allCards,
    });

    return res;
  };

  deleteCards = async (ids: number[]) => {
    const data = await cardsApi.deleteEntities({ ids });

    if (data.res?.length) {
      data.res.forEach((entry) => {
        showErrorNotification({ header: `${entry.error.description}, id: ${entry.id}` });
      });
    }

    const successfulRequests = ids.filter((id) => !data.res?.find((item) => item.id === id));

    if (successfulRequests.length) {
      showInfoNotification({ header: t('messages.cardsDeleted') });
    }

    return data;
  };

  private prepareCardDto = <T extends { data: CardFormData }>(cardData: T): T => {
    const newData: CardFormData = {};

    for (const key in cardData.data) {
      const dataValue = cardData.data[key];

      if (!dataValue || (Array.isArray(dataValue) && !dataValue.length)) continue;

      newData[key] = dataValue;
    }

    return { ...cardData, data: newData };
  };

  createCard = async (entity: CreateCardDto) => {
    const cardResponse = await cardsApi.createEntity<CreateCardDto, ICard>({
      entity: this.prepareCardDto(entity),
    });

    if (cardResponse.res) {
      showInfoNotification({ header: t('multipass.cardSuccessfullyCreated') });
    }

    return cardResponse;
  };

  editCard = async ({ id, entity }: { id: number; entity: EditCardDto }) => {
    const cardResponse = await cardsApi.updateEntityById<EditCardDto, ICard>({
      id,
      entity: this.prepareCardDto(entity),
    });

    if (cardResponse.res) {
      showInfoNotification({ header: t('multipass.cardSuccessfullyEdited') });
    }

    return cardResponse;
  };

  getById = async (id: number, controller?: AbortController) => {
    const res = await cardsApi.getEntityById<ICard>({
      id,
      deduplicate: true,
      onGetResponse: () => {
        if (window.location.href.includes(ROUTES.MULTIPASS_CARD_CREATION)) {
          void this.subscribeToCardFilesSseEvents({ cardId: id });
        }
      },
    });

    return res;
  };

  exportCards = async (ids: number[]) => {
    return await rootApiService.callAPI({
      path: 'card/export',
      method: METHODS.POST,
      body: ids,
    });
  };

  getMediaFileProcesses = async ({
    cardId,
    fileGroup,
    ids,
    additionalFilters,
  }: {
    cardId: number;
    fileGroup?: FileGroupType[];
    ids?: number[];
    additionalFilters?: IApiFieldFilter[];
  }) => {
    const fields: IApiFieldFilter[] = [];

    if (fileGroup) {
      fields.push({
        fieldId: 'fileGroup',
        targetValues: fileGroup.map(String),
        predicate: Predicate.EQ,
      });
    }

    if (ids && ids.length > 0) {
      fields.push({
        fieldId: 'id',
        targetValues: ids.map(String),
        predicate: Predicate.EQ,
      });
    }

    if (additionalFilters) {
      fields.push(...additionalFilters);
    }

    return await rootApiService.callAPI<IFileLink[]>({
      path: `card/${cardId}/file-links/filter`,
      method: METHODS.POST,
      body: { fields },
    });
  };

  createMediaFileProcesses = async ({
    cardId,
    fileGroup,
    file,
    recordSessionId,
    faceInteractiveMode,
  }: {
    cardId: number;
    fileGroup: FileGroupType;
    file: File;
    recordSessionId?: string;
    faceInteractiveMode?: FaceInteractiveMode;
  }) => {
    const formData = new FormData();
    if (fileGroup) {
      formData.append('fileGroup', fileGroup);
    }

    if (recordSessionId) {
      formData.append('recordSessionId', recordSessionId);
    }

    if (faceInteractiveMode) {
      formData.append('faceInteractiveMode', faceInteractiveMode);
    }

    formData.append('mediaFile', file);

    return await rootApiService.callAPI<IFileLink>({
      path: `card/${cardId}/file-links`,
      body: formData,
      ignoreContentType: true,
      method: METHODS.POST,
      shouldShowErrorNotification: (error) => error.statusCode !== StatusCodes.NOT_FOUND,
    });
  };

  setCardModel = async ({ cardId, modelId }: { cardId: number; modelId: number }) => {
    return await rootApiService.callAPI<ICard>({
      path: `card/${cardId}/model/${modelId}`,
      method: METHODS.PUT,
    });
  };

  deleteMediaFilesFromCard = async ({ entityId, ids }: { entityId: number; ids: number[] }) => {
    return await rootApiService.callAPI({
      path: `card/${entityId}/file-links`,
      method: METHODS.DELETE,
      body: ids,
    });
  };

  subscribeToCardsSseEvents = async ({ cardIds }: { cardIds: number[] }) => {
    if (!rootApiService.sseStore?.currentSseConnectionId) {
      console.error('service is not initialized');

      return;
    }

    await rootApiService.callAPI({
      path: 'card/events',
      method: METHODS.PUT,
      body: {
        guid: rootApiService.sseStore.currentSseConnectionId,
        updates: [
          {
            eventType: SseMessageType.CardUpdated,
            itemIds: cardIds,
          },
          {
            eventType: SseMessageType.CardFoundInHotlist,
            itemIds: cardIds,
          },
        ],
      },
    });
  };

  subscribeToCardFilesSseEvents = async ({ cardId }: { cardId: number }) => {
    if (!rootApiService.sseStore?.currentSseConnectionId) {
      console.error('service is not initialized');

      return;
    }

    return await rootApiService.callAPI({
      path: 'card/events',
      method: METHODS.PUT,
      body: {
        guid: rootApiService.sseStore.currentSseConnectionId,
        updates: [
          {
            eventType: SseMessageType.CardUpdated,
            itemIds: [cardId],
          },
          {
            eventType: SseMessageType.CardFoundInHotlist,
            itemIds: [cardId],
          },
          {
            eventType: SseMessageType.CardFileCreated,
            itemIds: [cardId],
          },
          {
            eventType: SseMessageType.CardFileUpdated,
            itemIds: [cardId],
          },
          {
            eventType: SseMessageType.CardFilesDeleted,
            itemIds: [cardId],
          },
        ],
      },
    });
  };

  getCount = async () => await cardsApi.getCount();

  subscribeToRecordSessionSseEvents = async (recordSessionId: string, cardId: number) => {
    if (!rootApiService.sseStore?.currentSseConnectionId) {
      console.error('service is not initialized');

      return;
    }

    return await rootApiService.callAPI({
      path: 'card/session/events',
      method: METHODS.PUT,
      body: {
        guid: rootApiService.sseStore.currentSseConnectionId,
        recordSessionId: recordSessionId,
        cardId: cardId,
      },
    });
  };

  processAudioFrame = async ({
    cardId,
    file,
    recordSessionId,
  }: {
    cardId: number;
    file: File;
    recordSessionId: string;
  }) => {
    const formData = new FormData();
    formData.append('recordSessionId', recordSessionId);
    formData.append('mediaFile', file);

    return await rootApiService.callAPI<IFileLink>({
      path: `card/${cardId}/frame`,
      body: formData,
      ignoreContentType: true,
      method: METHODS.POST,
    });
  };

  createRecordSession = async ({
    cardId,
    recordSessionId,
  }: {
    cardId: number;
    recordSessionId: string;
  }) => {
    return await rootApiService.callAPI({
      path: `card/${cardId}/session?recordSessionId=${recordSessionId}`,
      ignoreContentType: true,
      method: METHODS.POST,
    });
  };

  pingRecordSession = async ({
    cardId,
    recordSessionId,
  }: {
    cardId: number;
    recordSessionId: string;
  }) => {
    return await rootApiService.callAPI({
      path: `card/${cardId}/session/ping?recordSessionId=${recordSessionId}`,
      ignoreContentType: true,
      method: METHODS.POST,
      shouldShowErrorNotification: (error) => error.statusCode !== StatusCodes.NOT_FOUND,
    });
  };

  checkRecordSessionState = async ({
    cardId,
    recordSessionId,
  }: {
    cardId: number;
    recordSessionId: string;
  }) => {
    return await rootApiService.callAPI<{
      cardId: number;
      lastActive: string;
      sessionId: string;
      timeSpeechRequired: number;
      timeSpeechTotal: number;
    }>({
      path: `card/${cardId}/session/state?recordSessionId=${recordSessionId}`,
      method: METHODS.GET,
      shouldShowErrorNotification: (error) => error.statusCode !== StatusCodes.NOT_FOUND,
    });
  };

  createComment = async (comment: string | null, cardId: number, linkId: number) => {
    const APIOptions: CallAPIType = {
      path: `card/${cardId}/file-links/${linkId}/photo-comment`,
      method: METHODS.PUT,
      body: {
        comment,
      },
    };
    const apiResponse = await rootApiService.callAPI(APIOptions);
    if (apiResponse.res) {
      showInfoNotification({ header: t('messages.commentUpdated') });

      return true;
    }

    return false;
  };
}

export const cardsService = new CardsService();
