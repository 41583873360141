import { DefaultUserRoles, IUser, ROUTES } from '@/types';
import { useCallback, useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { useHavePermissions } from './useHavePermissions';
import { PrivilegeCode } from '@/types/role';
import { permissionControl } from '@/types/permissionControl';
import { checkHasRole } from '@/utils/checkHasRole';

export const routeToPermissionControlValue: Record<keyof typeof ROUTES, PrivilegeCode[][] | ''> = {
  AUTH: '',
  SETS: '',
  SEARCH_QUERY: '',
  SEARCH_QUERY_DETAILS: '',
  IMAGE_COMPARE: '',

  MULTIPASS: permissionControl.enrollment.page,
  MULTIPASS_LOAD_FILE: '',
  MULTIPASS_CARD_CREATION: permissionControl.enrollment.page,
  MULTIPASS_CARD_EDIT: permissionControl.enrollment.page,
  MULTIPASS_INTERACTIVE_CARD: '',
  MULTIPASS_FILLING_CABINET: permissionControl.enrollment.fillingCabinet.page,

  ADMINISTRATION: permissionControl.administration.page,
  ADMINISTRATION_USERS: permissionControl.administration.users.page,
  ADMINISTRATION_ROLES: permissionControl.administration.roles.page,
  ADMINISTRATION_GROUPS: permissionControl.administration.groups.page,

  STOPLIST: permissionControl.stoplist.page,
  STOPLIST_RECORD_CREATE: permissionControl.stoplist.page,
  STOPLIST_RECORD_EDIT: permissionControl.stoplist.page,

  DEVICES: permissionControl.devices.page,
};

export const useHavePermissionsToPage = (userData: IUser) => {
  const { havePermissions } = useHavePermissions(userData);

  const getAllowedRoutes = () => {
    return Object.keys(routeToPermissionControlValue)
      .filter((routeKey) => {
        const condition = routeToPermissionControlValue[routeKey as keyof typeof ROUTES];

        return condition ? havePermissions(condition) : false;
      })
      .map((routeKey) => ROUTES[routeKey as keyof typeof ROUTES] || '');
  };

  const allowedRoutes = useMemo(getAllowedRoutes, [havePermissions]);

  const checkIsPageAllowed = useCallback(
    (route: string) => {
      return allowedRoutes.some((allowedRoute) => matchPath(allowedRoute, route));
    },
    [allowedRoutes]
  );

  const getDefaultRoute = () => {
    const roles = userData.roles;
    const isAdmin = checkHasRole(roles, DefaultUserRoles.ADMIN);
    const isOperator = checkHasRole(roles, DefaultUserRoles.OPERATOR);
    const isSupervisor = checkHasRole(roles, DefaultUserRoles.SUPERVISOR);

    if (isAdmin && !isOperator && !isSupervisor) {
      return ROUTES.ADMINISTRATION_USERS;
    }

    if (isSupervisor || isOperator) {
      return ROUTES.MULTIPASS_FILLING_CABINET;
    }

    return allowedRoutes[0] || null;
  };

  return { checkIsPageAllowed, defaultRoute: getDefaultRoute() };
};
