import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack';
import { GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { InputBaseProps } from '@mui/material';
import { EDataGridStoreSortMode } from './types/grid';
import { PrivilegeCode } from './types/role';
import { Predicate } from './types/predicate';

export type TosterOptionsType = {
  snackbarAction: SnackbarActionType;
  message?: string;
};

export enum METHODS {
  PUT = 'PUT',
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
}

export type FormFieldError = {
  key: string;
  errors: string[];
};

export type ErrorType = {
  code: string;
  description: string;
  details?: null | [] | ProcessErrorDetails | { errors: FormFieldError[] };
};

export type ProcessErrorDetails = {
  process_id: number | undefined;
};

export enum ERROR_CODES {
  INSUFFICIENT_STORAGE = 'INSUFFICIENT_STORAGE',
  // TODO: на бэке добавить специальный error_code и проверять на фронте по коду, а не описанию
  PROCESS_ALREADY_EXISTS = 'Процесс с этими параметрами уже есть в базе данных',
}

export type APIResponseType<T = null> = {
  statusCode: number | null;
  errorDescription: ErrorType | null;
  res: T | null;
};

export type ShouldShowErrorNotification = (result: ErrorType & { statusCode: number }) => boolean;

export type CallAPIType = {
  path: string;
  tosterOptions?: TosterOptionsType;
  method?: METHODS;
  headers?: Map<string, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any | null | undefined;
  ignoreBody?: boolean;
  ignoreContentType?: boolean;
  shouldShowErrorNotification?: ShouldShowErrorNotification;
};

export enum Status {
  FORBIDDEN = 403,
}

export interface IApiFieldFilter {
  fieldId: string;
  predicate: Predicate;
  targetValues?: (string | null | boolean)[];
}

export interface ISortByField<T> {
  name: keyof T;
  order: EDataGridStoreSortMode;
}

export interface IFilterDto<T> {
  fields?: IApiFieldFilter[];
  fastFields?: IApiFieldFilter[];
  orFields?: IApiFieldFilter[];
  limit: number;
  offset?: number;
  before?: T | null;
  after?: T | null;
  sortByFields?: ISortByField<T>[];
  jsonFields?: IApiJsonFieldFilter[];
  operator?: 'Or' | 'And';
}

export interface IApiJsonFieldFilter {
  fieldId: string;
  targetValues: (string | null)[];
  predicate: Predicate;
  jsonFieldId: string;
}

export interface IAuthProps {
  username: string;
  password: string;
}

export enum AUTH_TYPE {
  T4F = 'T4F',
  T4 = 'T4',
}

export enum EMultipassRoute {
  desktop = 'desktop',
  loadFile = 'loadFile',
  cardCreation = 'entry',
  cardEdit = 'entry/:id',
  interactiveCard = 'interactiveCard',
  filingCabinet = 'filingCabinet',
}

export enum EAdministrationRoute {
  users = 'users',
  roles = 'roles',
  groups = 'groups',
}

export enum EStoplistRoute {
  recordCreate = 'entry',
  recordEdit = 'entry/:id',
}

const MULTIPASS_ROUTE = '/multipass';
const ADMINISTRATION_ROUTE = '/administration';
const STOPLIST_ROUTE = '/stoplist';
const DEVICES_ROUTE = '/devices';

export const ROUTES = {
  AUTH: '/',
  SETS: '/sets',
  SEARCH_QUERY: '/request',
  SEARCH_QUERY_DETAILS: '/request/details',
  IMAGE_COMPARE: '/image-compare',

  MULTIPASS: MULTIPASS_ROUTE,
  MULTIPASS_LOAD_FILE: `${MULTIPASS_ROUTE}/${EMultipassRoute.loadFile}`,
  MULTIPASS_CARD_CREATION: `${MULTIPASS_ROUTE}/${EMultipassRoute.cardCreation}`,
  MULTIPASS_CARD_EDIT: `${MULTIPASS_ROUTE}/${EMultipassRoute.cardEdit}`,
  MULTIPASS_INTERACTIVE_CARD: `${MULTIPASS_ROUTE}/${EMultipassRoute.interactiveCard}`,
  MULTIPASS_FILLING_CABINET: `${MULTIPASS_ROUTE}/${EMultipassRoute.filingCabinet}`,

  ADMINISTRATION: ADMINISTRATION_ROUTE,
  ADMINISTRATION_USERS: `${ADMINISTRATION_ROUTE}/${EAdministrationRoute.users}`,
  ADMINISTRATION_ROLES: `${ADMINISTRATION_ROUTE}/${EAdministrationRoute.roles}`,
  ADMINISTRATION_GROUPS: `${ADMINISTRATION_ROUTE}/${EAdministrationRoute.groups}`,

  STOPLIST: STOPLIST_ROUTE,
  STOPLIST_RECORD_CREATE: `${STOPLIST_ROUTE}/${EStoplistRoute.recordCreate}`,
  STOPLIST_RECORD_EDIT: `${STOPLIST_ROUTE}/${EStoplistRoute.recordEdit}`,
  DEVICES: DEVICES_ROUTE,
};

export type Process = {
  id: number;
  json_request?: {
    type: string;
    job_id: number;
    markers: MARKERS[];
    ident_threshold: number | null;
  };
  progress?: number;
  access_mode?: string;
  created_time?: string;
  started_time?: string;
  stopped_time?: string;
  result?: {
    archive_uri: string;
  };
  status?: string;
  process_type?: ProcessType;
  offset?: number | null;
  error?: {
    code: string;
    description: string;
  } | null;
  lifetime?: number;
};

export enum ProcessType {
  CREATE_REPORT_ARCHIVE = 'CREATE_REPORT_ARCHIVE',
  CREATE_EXCEL_REPORT = 'CREATE_EXCEL_BORDER_KS_REPORT',
}

export enum ProcessTypeName {
  CREATE_REPORT_ARCHIVE = 'CSV',
  CREATE_EXCEL_BORDER_KS_REPORT = 'Excel',
}

export enum DefaultUserRoles {
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR',
  SUPERVISOR = 'SUPERVISOR',
}

export interface IRole {
  id: number;
  roleName: string;
}

export interface IUser {
  id: number;
  username: string;
  roles: IRole[];
  privileges: PrivilegeCode[];
  token_privileges: PrivilegeCode[];
  auth_type: AUTH_TYPE;
  t4f_token: string;
  card_token: string;
  auth: boolean;
  newPasswordRequired: boolean;
  firstname: string;
  lastname: string;
  middlename: string | null;
}

export interface ILogInByCardTokenResponse {
  cardToken: string;
  privileges: PrivilegeCode[];
}

export interface IUserToBeCreated {
  username: string;
  password: string;
  firstname: string;
  lastname: string;
  middlename: string;
  description: string;
  roles: number[];
  department: number | null;
}

export interface IUserToBeUpdated {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  middlename: string;
  description: string;
  roles: number[];
  department: number | null;
}

export interface IUserToChangePassword {
  id: number;
  newPassword: string;
  repeatPassword: string;
}

type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;

type NumberRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;

export type PercentType = NumberRange<0, 101>;

export enum ProgressStages {
  NEW = 'NEW', //legacy
  QUEUED = 'QUEUED', //legacy
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE', //legacy
  CANCELED = 'CANCELED', //legacy
  ERROR = 'ERROR',
  SUCCESSFUL = 'SUCCESSFUL',
}

export interface IProgressProps {
  stage: string;
  progress?: PercentType;
}

export interface IProgressStepProps extends IProgressProps {
  index?: number;
  length?: number;
  error?: boolean;
  message?: ErrorType;
}

export type StepIconType = {
  stage: ProgressStages;
};

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NONE = 'NONE',
  ERROR = 'ERROR',
}

type TPairsfield = { name: string; value: string };
type TPairsrow = {
  fields: TPairsfield[];
  original_photo_uri: string;
  normalized_photo_uri: string;
};
export interface IPair {
  id: number;
  row_a: TPairsrow;
  row_b: TPairsrow;
  probability: number;
}
export type TError = { code: string; description: string };

export enum JobType {
  METAFAKE = 'METAFAKE',
  METAFAKE_SUBSET = 'METAFAKE_SUBSET',
  PHOTOFAKE = 'PHOTOFAKE',
  PHOTOFAKE_SUBSET = 'PHOTOFAKE_SUBSET',
  PHOTOCROSS = 'PHOTOCROSS_SUBSET',
  PHOTOCROSS_SUBSET = 'PHOTOCROSS_SUBSET',
  T4_SEARCH_QUERY = 'T4_SEARCH_QUERY',
}

export enum TaskType {
  CREATE_MODEL_AND_NORMALIZE_IMAGE = 'CREATE_MODEL_AND_NORMALIZE_IMAGE',
  COMPARE_MODELS = 'COMPARE_MODELS',
  NORMALIZE_IMAGE = 'NORMALIZE_IMAGE',
  AUTODETECT_SPEAKERS_AND_VOICE_RANGES = 'AUTODETECT_SPEAKERS_AND_VOICE_RANGES',
}

export interface IPerson {
  fields: Record<string, string>;
  original_photo_uri: string;
  normalized_photo_uri: string;
}

export type ColumnPropsType = {
  field: string;
  headerName: string;
};

export type selectedPairsType = {
  row: PairType;
  columns: ColumnPropsType[];
  id: GridRowId;
  marker: MARKERS;
} | null;

export type PairType = {
  id: GridRowId;
  row_a: IPerson;
  row_b: IPerson;
  probability: number;
  marker: MARKERS;
};

export type PagingType = {
  page: number;
  size: number;
  pages_total: number;
};

export interface ICouple {
  pairs: PairType[];
  paging: PagingType;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface InfoType {
  columns: ColumnPropsType[] | [];
  hidden: string[] | null | number[];
}

export type TResultModalProps = {
  rows: PairType[] | [];
  paging: PagingType;
  columns: ColumnPropsType[] | [];
  selectionModel: GridSelectionModel;
  hiddenColumns: string[] | null | number[];
  setInfo: ({ columns, hidden }: InfoType) => void;
  setReports: ({ pairs, paging }: ICouple, isPagination: boolean) => void;
  changePaging: (paging: PagingType) => void;
  changePage: (page: number) => void;
  resetPaging: () => void;
  setSelectionModel: (rowId: GridSelectionModel) => void;
  setPageIfOutOfRange: (page: number) => void;
};

export enum MARKERS {
  YES = 'YES',
  NO = 'NO',
  MAYBE = 'MAYBE',
  EMPTY = 'EMPTY',
}

export const markerColor = {
  [MARKERS.YES]: '#0E9F6E',
  [MARKERS.NO]: '#D03801',
  [MARKERS.MAYBE]: '#FFA000',
  [MARKERS.EMPTY]: '#525965',
};

export const markerColorBorder = {
  [MARKERS.YES]: markerColor[MARKERS.YES],
  [MARKERS.NO]: markerColor[MARKERS.NO],
  [MARKERS.MAYBE]: markerColor[MARKERS.MAYBE],
  [MARKERS.EMPTY]: '#008697',
};

export interface IEtalonFields {
  1: string;
  2: string;
  3: string;
  4: number;
  5: number;
  6: number;
  '7g': string;
}

export interface IEtalonsInfo {
  crop: string;
  model: string;
  avatar: boolean;
  file_name: string;
  original: string;
  probability: number;
  file_extension: string;
}

export enum FILETYPE {
  IMAGE = 'img',
  SOUND = 'audio',
}

export interface ISearchQueryError {
  code: string;
  description: string;
}

export interface IInfo {
  key: string;
  value: string;
}

export interface ISmallError {
  code: string;
  description: string;
  details: IErrorDetail[];
}

export interface IErrorDetail {
  ключ: string;
  'имя файла': string;
}

export interface IConfig {
  developMode: boolean;
}

export interface IFieldFilter {
  Predicate: string;
  Value: string;
}

export interface IYearRange {
  From: number;
  To: number;
}

export type ChannelNumber = 'Left' | 'Right';
export interface ISpeaker {
  voice_model_uri: string;
  channel_number: ChannelNumber;
  speaker_number: number;
  speech_duration: number;
  /* Список интервалов эффективной разметки диктора в формате
  [начало 1-го участка, конец 1-го участка, начало 2-го участка, конец 2-го участка, ... , начало n-го участка, конец n-го участка], 
  данные в сек. */
  ranges: number[];
}

export type Image = {
  url: string;
  hash: string;
  faces: Face[];
};

export type Face = {
  modelUri: string;
  normalizedImageUri?: string;
  area?: ModelArea;
  comparisons?: Face[];
  relevance?: number;
  index?: number;
  areaNumber?: number;
};

export type FaceModel = {
  model: string;
  area: ModelArea;
  normalized_image_uri: string;
};

export type ModelArea = {
  x: number;
  y: number;
  h: number;
  w: number;
};

export type ComparisonResult = {
  etalons: Comparison[];
  model_uri: string;
};

export type Comparison = {
  relevance: number;
  etalon_uri: string;
};

export type ErrorsReportType = {
  code: string;
  description: string;
  details: DetailsType[] | null;
};

type DetailsType = Record<string, string>;

export interface ISqFileInfo {
  key: string;
  name: string;
  models?: string[];
}

export type SnackbarActionType = (
  message: SnackbarMessage,
  options?: OptionsObject | undefined
) => SnackbarKey;

export interface IPaging {
  page: number;
  size: number;
  pages_total: number;
}

export interface IT4FaceSearchReportEtalonInfo {
  etalon_model: string;
  card_id: string;
  probability: number;
  marker: MARKERS;
  gender: Gender;
  fields: { [key: string]: string };
  etalons_info: IFaceEtalonInfo[];
}

export interface IReportAttributes {
  hidden_columns: string[];
  compared_columns: string[];
}

export interface IFaceTargetFileInfo {
  file: string;
  models: ITargetFaceModelResultInfo[];
  file_name: string;
}

export interface ITargetFaceModelResultInfo {
  model: string;
  comparison_count: number;
  normalized_image: string;
}

export type TTargetFileBase = ITargetFaceModelResultInfo & Pick<IFaceTargetFileInfo, 'file_name'>;

export interface IFaceEtalonInfo {
  original: string;
  probability: number;
  avatar: boolean;
  crop: string;
  normalized: string;
  model: string;
  file_name: string;
  file_extension: string;
}

export enum ESearchBaseInfo {
  FACE_SEARCH = 'FACE_SEARCH',
  VOICE_SEARCH = 'VOICE_SEARCH',
}

export interface IGetReportEtalonsProps {
  sq_id: number;
  target: string;
  page: number;
  size: number;
}

export interface IPutReportMarkerProps {
  sq_id: number;
  target: string;
  etalon: string;
  marker: MARKERS;
}

export interface IPutNormalizedProps {
  sq_id: number;
  etalon: string;
  etalon_img: string;
  normalized: string;
}

export interface ISystem {
  id: string;
  name: string;
}

export type AudioProcessing = 'Enroll' | 'HardcoreEnroll';

export enum CONSTANTS {
  EX_SYSTEM = 'const.search-query.filters-data-source',
  FACE_IDENT_THRESHOLD = 'const.face.ident-threshold',
  FACE_MIN_IDENT_THRESHOLD = 'const.face.min-ident-threshold',
  VOICE_IDENT_THRESHOLD = 'const.voice.ident-threshold',
  VOICE_MIN_IDENT_THRESHOLD = 'const.voice.min-ident-threshold',
  ATTACHED_MAX_COUNT = 'const.search-query.attached-files-max-count',
  ATTACHED_MAX_SIZE = 'const.search-query.attached-files-max-size-mb',
  DISPLAY_SETS_TAB = 'const.sets.display-sets-tab',
  LANGUAGE = 'const.locale',
  IMAGE_EXTENSIONS = 'const.image-extensions',
  BIOSCANNER_API_URL = 'const.api-url',
  AUDIO_PROCESSING_TYPE = 'const.file-queue-audio-processing',
  LOAD_FROM_EXTERNAL = 'const.load-from-external',
  FRAME_SPEECH_TIME_SEC = 'const.frame-speech-time-sec',
  ALLOWED_DEVICES = 'const.allowed-devices',
  MAX_MANUAL_INTERACTIVE_PHOTOS_COUNT = 'const.max-manual-interactive-photos-count',
  MAX_AUTO_INTERACTIVE_PHOTOS_COUNT = 'const.max-auto-interactive-photos-count',
  INTERACTIVE_SESSION_TIMEOUT_SEC = 'const.interactive-session-timeout-sec',
  FACTOR_VERSION = 'const.factor-version',
}

export interface IField {
  name: string;
  ui_name: string;
  ui_label: string;
  control_type: string;
  type: string;
  init_value: number | boolean | string;
  required: boolean | string;
  regex: string;
  options?: string[];
}

export interface IThresholdField {
  name: string;
  ui_name: string;
  init_value: number | boolean | string;
}

export interface IFieldsProps extends InputBaseProps {
  queryLists: IField[];
}

export type JobTypeDescriptionMap = { name: string; description: string };

export enum FORM_TYPES {
  TEXT = 'text',
  SLIDER = 'slider',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

export type TFieldLists = {
  main: IField[];
  secondary: IField[];
};

export type HeaderInfoType = {
  name: string;
  description: number | boolean | string;
};

export type Constants = {
  constFileMaxCount: number;
  constFileMaxSize: number;
  constImageThreshold: number;
  constImageMinThreshold: number;
  constSoundThreshold: number;
  constSoundMinThreshold: number;
  constExtSystemList: ISystem[];
  constDisplaySetsTab: boolean;
};

export type MenuTab = {
  title: string;
  route: string;
  onClick: () => void;
};

export type Language = 'en' | 'ru';

export type LanguageOptions = {
  [key in Language]: {
    label: string;
  };
};

export type LocalizedFields<Keys extends string, Values> = {
  [key in Language]: {
    [key in Keys]: Values;
  };
};
