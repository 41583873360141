import rootApiService from './root.api.service';
import { CallAPIType, METHODS } from '@/types';
import { ApiError } from '@/utils/ApiError';
import { IFileInfoResponse } from '@/apiTypes';
import { EntityApi } from './entityApi.service';
import { DataProvider } from '@/types/grid';
import {
  IFileLink,
  IFileProcessResponse,
  IFileProcessUpdate,
  IProcessPathRequest,
  IProcessFileRequest,
} from '@/types/file';
import { enqueueSnackbar } from 'notistack';
import { showErrorNotification } from '@/utils/notifications';
import { SseMessageType } from '@/types/sse';

const fileProcessingApi = new EntityApi('processing/file-processes');

class FileProcessApi {
  dataProvider: DataProvider<IFileLink> = async (state, allCards?: IFileLink[]) => {
    const res = await fileProcessingApi.getFiltersData({
      filters: state,
      localData: allCards,
    });

    void this.subscribeToFilesSseEvents({
      fileIds: res.data.map((file) => file.id),
    });

    return res;
  };

  getCount = async () => await fileProcessingApi.getCount();

  subscribeToFilesSseEvents = async ({ fileIds }: { fileIds: number[] }) => {
    if (!rootApiService.sseStore?.currentSseConnectionId) {
      console.error('service is not initialized');

      return;
    }

    await rootApiService.callAPI({
      path: 'processing/file-processes/file-process/events',
      method: METHODS.PUT,
      body: {
        guid: rootApiService.sseStore.currentSseConnectionId,
        updates: [
          {
            eventType: SseMessageType.FileProcessChanged,
            itemIds: fileIds,
          },
          {
            eventType: SseMessageType.FileProcessDeleted,
            itemIds: fileIds,
          },
        ],
      },
    });
  };

  getFilesByIds = async (ids: number[]): Promise<IFileLink[] | null> => {
    const APIOptions: CallAPIType = {
      path: 'processing/file-processes/byIds',
      method: METHODS.POST,
      body: ids,
    };
    const apiResponse = await rootApiService.callAPI<IFileLink[]>(APIOptions);

    if (apiResponse.res) {
      return apiResponse.res;
    } else if (apiResponse.statusCode !== 404) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return null;
  };

  addFiles = async ({ processingParameters, file }: IProcessFileRequest): Promise<boolean> => {
    const formData = new FormData();

    formData.append(
      'processingParameters',
      JSON.stringify({ processingParameters: processingParameters })
    );

    formData.append('mediaFile', file);

    const APIOptions: CallAPIType = {
      path: 'processing/file-processes',
      body: formData,
      method: METHODS.POST,
      ignoreContentType: true,
    };

    const apiResponse = await rootApiService.callAPI<IFileProcessResponse>(APIOptions);

    return !!apiResponse.res;
  };

  addFilesFromPath = async (params: IProcessPathRequest): Promise<void> => {
    const APIOptions: CallAPIType = {
      path: 'processing/file-processes/from-path',
      method: METHODS.POST,
      body: params,
    };
    const apiResponse = await rootApiService.callAPI<IFileProcessResponse>(APIOptions);

    apiResponse.res?.errors.forEach((error) =>
      showErrorNotification({ header: error.description })
    );
  };

  deleteFileById = async (id: number): Promise<boolean> => {
    const APIOptions: CallAPIType = {
      path: `processing/file-processes/${id}`,
      method: METHODS.DELETE,
      tosterOptions: {
        snackbarAction: enqueueSnackbar,
      },
    };
    const apiResponse = await rootApiService.callAPI<IFileProcessResponse>(APIOptions);

    return !!apiResponse.res;
  };

  deleteFinishedFileProcesses = async (): Promise<boolean> => {
    const APIOptions: CallAPIType = {
      path: 'processing/file-processes/finished',
      method: METHODS.DELETE,
    };

    const apiResponse = await rootApiService.callAPI<IFileProcessResponse>(APIOptions);
    if (apiResponse.res && apiResponse.res.errors?.length > 0) {
      apiResponse.res.errors.forEach((e) => {
        showErrorNotification({ header: e.description });
      });

      return false;
    }

    if (apiResponse.errorDescription) {
      return false;
    }

    return true;
  };

  update = async (updateStatus: IFileProcessUpdate): Promise<boolean> => {
    const APIOptions: CallAPIType = {
      path: 'processing/file-processes/update',
      method: METHODS.POST,
      body: {
        ...updateStatus,
      },
    };
    const apiResponse = await rootApiService.callAPI<IFileInfoResponse>(APIOptions);

    return !!apiResponse.res;
  };

  getPositionInQueue = async (taskId: number) => {
    const APIOptions: CallAPIType = {
      path: `processing/file-processes/${taskId}/count-before`,
      method: METHODS.GET,
    };

    return await rootApiService.callAPI<number>(APIOptions);
  };
}

export default new FileProcessApi();
