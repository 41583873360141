import { FileGroupType, EFileProcessStatus, FaceInteractiveMode } from './file';
import { IGroup } from './group';
import { Model, PortraitCharacteristics } from './model';

export type CardFormData = Record<string, string | string[] | null | CardFormData[]>;

export interface CreateCardDto {
  entryTemplateId: number | null;
  data: CardFormData;
  modelId: number | null;
  cardId: number | null;
}

export interface EditCardDto {
  entryTemplateId: number;
  data: CardFormData;
}

export type CardExportGridStatus = 'SUCCESS' | 'WARNING' | 'FAILED';

export interface CardsExportInfo {
  id: number;
  type: string;
  status: 'IN_PROGRESS' | 'NOT_STARTED' | 'COMPLETED' | 'FAILED';
  startedTime: string;
  finishedTime: string;
  errorDescription: string;
}

export enum ECardExportStatus {
  NEW = 'NEW',
  NEED_EXPORT = 'NEED_EXPORT',
  EXPORTED = 'EXPORTED',
}

export interface IFace {
  modelId: number;
  normalizedImageCorrelationId: string;
  normalizedImageUri: string;
  originalImageCorrelationId: string;
  originalImageUri: string;
  area: { h: number; w: number; x: number; y: number };
  quality: number;
  portraitCharacteristics: PortraitCharacteristics[];
}

export interface CardPhoto {
  id: number;
  cardId: number;
  path: string;
  fileProcessId: number;
  faceData: IFace[];
  isMain: boolean;
  isNormalized: boolean;
  fileGroup: FileGroupType;
  createdTime: string;
  recordSessionId: string | null;
  status: EFileProcessStatus;
  faceInteractiveMode: FaceInteractiveMode | null;
}

type User = {
  id: number;
  isEnabled: boolean;
  name: string;
  username: string;
};

export type ICard = {
  id: number;
  createdOnDateTime: string;
  editedOnDateTime: string;
  accessLevel: string;
  entryTemplateId: number;
  accessGroups: string[];
  department: IGroup | null;
  data: CardFormData;
  exportStatus: ECardExportStatus;
  exportResultStatus: CardExportGridStatus | null;
  faceModel: Model | null;
  voiceModel: Model | null;
  photoPath: string | null;
  audioPath: string | null;
  exportedOnDateTime: string;
  creator: User | null;
  editor?: User;
  totalFiles: number;
  sourceFiles: string[] | null;
  isDuplicate: boolean;

  isInHotlist: boolean;
  device?: DeviceDto;

  strField1: string | null;
  strField2: string | null;
  strField3: string | null;
  strField4: string | null;
  strField5: string | null;
  strField6: string | null;
  strField7: string | null;
  strField8: string | null;
  strField9: string | null;
  strField10: string | null;
  dateField1: string | null;
  dateField2: string | null;
  dateField3: string | null;
  dateField4: string | null;
  dateField5: string | null;

  // metadata
  // lastExportResultDate
};

type DeviceDto = {
  id: number;
  deviceId: string;
};

export type ICardsGridItem = Omit<
  ICard,
  'creator' | 'editor' | 'audioPath' | 'department' | 'device'
> & {
  creatorName: string;
  creatorId: number;
  editorName: string;
  editorId: number;
  voicePath: string | null;
  deviceName: string | null;
  documents: string; // required for quick filters panel
};
