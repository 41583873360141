import { ECardExportStatus, ICardsGridItem } from '@/types/card';
import { StoplistGridItem } from '@/types/stoplist';
import { Device } from '@/types/device';
import { IGridFilter } from '@/types/grid';
import { Predicate } from '@/types/predicate';
import {
  IOpenedCard,
  CardTab,
  SortOrder,
  BiometricItem,
  EntryTab,
  TabEntity,
} from '@/types/ui.types';
import { makeObservable, observable, action, ObservableMap } from 'mobx';
import { exportStatusId } from '@/components/SideFilter/constants';

interface FilingCabinetFilters {
  regularFilters: IGridFilter<ICardsGridItem>[];
  searchString: string;
  fastFilters: IGridFilter<ICardsGridItem>[];
}

interface StoplistGridFilters {
  regularFilters: IGridFilter<StoplistGridItem>[];
  searchString: string;
  fastFilters: IGridFilter<StoplistGridItem>[];
}

interface DevicesGridFilters {
  regularFilters: IGridFilter<Device>[];
  orFilters: IGridFilter<Device>[];
}

export class UiStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  openedCards: ObservableMap<number, IOpenedCard> = observable.map();

  @observable filingCabinetFilters: FilingCabinetFilters = {
    regularFilters: [],
    searchString: '',
    fastFilters: [],
  };

  @observable stoplistGridFilters: StoplistGridFilters = {
    regularFilters: [],
    fastFilters: [],
    searchString: '',
  };

  @observable devicesGridFilters: DevicesGridFilters = {
    regularFilters: [],
    orFilters: [],
  };

  getOpenedCard(cardId: number): IOpenedCard | undefined {
    return this.openedCards.get(cardId);
  }

  @action
  setFilingCabinetFilters = (callback: (prev: FilingCabinetFilters) => FilingCabinetFilters) => {
    this.filingCabinetFilters = callback(this.filingCabinetFilters);
  };

  @action
  setStoplistGridFilters = (callback: (prev: StoplistGridFilters) => StoplistGridFilters) => {
    this.stoplistGridFilters = callback(this.stoplistGridFilters);
  };

  setDevicesGridFilters = (callback: (prev: DevicesGridFilters) => DevicesGridFilters) => {
    this.devicesGridFilters = callback(this.devicesGridFilters);
  };

  @action
  setExportStatusQuickFilter = (value: ECardExportStatus[] | null) => {
    const updatedFilters = [...this.filingCabinetFilters.regularFilters];
    if (!value || !value.length) {
      this.filingCabinetFilters.regularFilters = updatedFilters.filter(
        (f) => f.fieldId !== exportStatusId
      );

      return;
    }

    const statusFilterIndex = updatedFilters.findIndex((f) => f.fieldId === exportStatusId);
    updatedFilters[statusFilterIndex > -1 ? statusFilterIndex : updatedFilters.length] = {
      fieldId: exportStatusId,
      targetValues: value,
      predicate: Predicate.CONTAINS,
    };

    this.filingCabinetFilters.regularFilters = updatedFilters;
  };

  @action
  saveOpenedCard(card: IOpenedCard) {
    this.openedCards.set(card.cardId, card);
  }

  @action
  initOpenedCard({ cardId, activeTab }: { cardId: number; activeTab?: CardTab }) {
    const initialSortOrder = new Map<EntryTab, SortOrder>();
    initialSortOrder.set('face', 'DESC');
    initialSortOrder.set('voice', 'DESC');
    initialSortOrder.set('documents', 'DESC');
    initialSortOrder.set('attachedFiles', 'DESC');

    const initialSelectedEntities = new Map<EntryTab, number[]>();
    initialSelectedEntities.set('face', []);
    initialSelectedEntities.set('voice', []);
    initialSelectedEntities.set('documents', []);
    initialSelectedEntities.set('attachedFiles', []);

    const initialTabEntities = new Map<EntryTab, TabEntity[]>();
    initialTabEntities.set('face', []);
    initialTabEntities.set('voice', []);
    initialTabEntities.set('documents', []);
    initialTabEntities.set('attachedFiles', []);

    this.saveOpenedCard({
      cardId,
      activeTab: activeTab || 'biometricModels',
      activeSideMenuItem: 'face',
      sideMenuCollapsed: false,
      selectedEntities: initialSelectedEntities,
      entities: initialTabEntities,
      sortOrder: initialSortOrder,
    });
  }

  @action
  closeCard(cardId: number) {
    this.openedCards.delete(cardId);
  }

  @action
  clear() {
    this.openedCards.clear();
  }

  @action
  setActiveTab(cardId: number, tab: CardTab) {
    const card = this.getOpenedCard(cardId);
    if (card) {
      card.activeTab = tab;
    }
  }

  @action
  setActiveSideMenuItem(cardId: number, menuItem: BiometricItem) {
    const card = this.getOpenedCard(cardId);
    if (card) {
      card.activeSideMenuItem = menuItem;
    }
  }

  @action
  setSideMenuCollapsed(cardId: number, collapsed: boolean) {
    const card = this.getOpenedCard(cardId);
    if (card) {
      card.sideMenuCollapsed = collapsed;
    }
  }

  @action
  setSelectedEntities(cardId: number, section: EntryTab, entities: number[]) {
    const card = this.getOpenedCard(cardId);
    if (card) {
      card.selectedEntities.set(section, entities);
    }
  }

  @action
  setEntities(cardId: number, section: EntryTab, entities: TabEntity[]) {
    const card = this.getOpenedCard(cardId);
    if (card) {
      const newEntityIds = entities.map((entity) => entity.id);
      const currentSelectedEntities = card.selectedEntities.get(section) || [];
      const filteredSelectedEntities = currentSelectedEntities.filter((id) =>
        newEntityIds.includes(id)
      );
      card.selectedEntities.set(section, filteredSelectedEntities);
      card.entities.set(section, entities);
    }
  }

  @action
  setSortOrder(cardId: number, section: EntryTab, order: SortOrder) {
    const card = this.getOpenedCard(cardId);
    if (card) {
      card.sortOrder.set(section, order);
    }
  }
}
