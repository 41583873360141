import { CallAPIType, METHODS } from '@/types';
import rootApiService from './root.api.service';
import { IFilingCabinetScheme } from '@/types/cardTemplate';

class StoplistTemplateService {
  getScheme = async () => {
    const APIOptions: CallAPIType = {
      path: 'stoplist-templates/scheme',
      method: METHODS.GET,
    };

    const res = await rootApiService.callAPI<IFilingCabinetScheme>(APIOptions);

    return res;
  };
}

export const stoplistTemplateService = new StoplistTemplateService();
