import { CardFormData } from './card';
import { Model } from './model';
import { User } from './user';

export interface StoplistRecord {
  id: number;
  createdOnDateTime: string;
  editedOnDateTime: string;
  accessLevel: AccessLevel;
  entryTemplateId: number;
  startTime: string;
  endTime: string;
  actionDescription: string;
  faceModel: Model | null;
  voiceModel: Model | null;
  data: CardFormData;
  // photo:
  // photoPath: string | null;
  creator: User;
  editor?: User;
  totalFiles: number;
  strField1: string | null;
  strField2: string | null;
  strField3: string | null;
  strField4: string | null;
  strField5: string | null;
  strField6: string | null;
  strField7: string | null;
  strField8: string | null;
  strField9: string | null;
  strField10: string | null;
  dateField1: string | null;
  dateField2: string | null;
  dateField3: string | null;
  dateField4: string | null;
  dateField5: string | null;
}

export type RecordType = 'card' | 'stoplist';

export type StoplistGridItem = Omit<StoplistRecord, 'creator' | 'editor' | 'faceModel'> & {
  creatorName: string;
  creatorId: number;
  editorName: string;
  editorId: number;
  photoPath: string | null;
  attachments: string; // required for quick filters panel
  faceModel: {
    type: 'FACE';
    id: number;
    quality: number;
  } | null;
};

export interface CreateStoplistDto {
  entryTemplateId: number | null;
  data: CardFormData;
  accessLevel: AccessLevel;
}

export enum AccessLevel {
  OPERATOR = 'OPERATOR',
  INSPECTOR = 'INSPECTOR',
}

export type StoplistNotification = {
  id: number;
  date: string;
  objectName: string;
  operator: string;
};

export interface EditRecordDto {
  entryTemplateId: number;
  data: CardFormData;
}
