import { cardTemplateService } from '@/services/cardTemplate.service';
import { stoplistTemplateService } from '@/services/stoplistTemplate.service';
import { TemplatesStoreType } from '@/stores/templates.store';
import { APIResponseType } from '@/types';
import { ECardExportStatus, ICardsGridItem } from '@/types/card';
import { IFilingCabinetScheme } from '@/types/cardTemplate';
import { StoplistGridItem } from '@/types/stoplist';
import { ConditionType } from 'stc-ui-kit';

export const StringFieldConditions = [
  ConditionType.Contains,
  ConditionType.Equals,
  ConditionType.NotEquals,
  ConditionType.StartsWith,
  ConditionType.EndsWith,
];
export const ListStringFieldConditions = [ConditionType.Contains];
export const DictFieldConditions = [ConditionType.Contains];

export const DateFieldConditions = [
  ConditionType.Equals,
  ConditionType.NotEquals,
  ConditionType.Less,
  ConditionType.Greater,
];
export const ListDictFieldConditions = [ConditionType.Contains];

export const cardExportStatuses: ECardExportStatus[] = [
  ECardExportStatus.NEW,
  ECardExportStatus.NEED_EXPORT,
  ECardExportStatus.EXPORTED,
];

export const exportStatusId: keyof ICardsGridItem = 'exportStatus';
export const isInHotlistId: keyof ICardsGridItem = 'isInHotlist';
export const cardId: keyof ICardsGridItem = 'id';

export const notTemplateFilterKeysCards: Array<keyof ICardsGridItem> = [
  isInHotlistId,
  exportStatusId,
  cardId,
];

export const stoplistId: keyof StoplistGridItem = 'id';
export const accessLevelId: keyof StoplistGridItem = 'accessLevel';
export const startTimeId: keyof StoplistGridItem = 'startTime';
export const endTimeId: keyof StoplistGridItem = 'endTime';

export const notTemplateFilterKeysStoplist: Array<keyof StoplistGridItem> = [
  stoplistId,
  accessLevelId,
  startTimeId,
  endTimeId,
];

interface TemplateStoreConstructorConstants {
  loadScheme: () => Promise<APIResponseType<IFilingCabinetScheme>>;
  notTemplateFilterKeys: Array<keyof ICardsGridItem | keyof StoplistGridItem>;
}

export const ConstantsDict: Record<TemplatesStoreType, TemplateStoreConstructorConstants> = {
  CardTemplates: {
    loadScheme: cardTemplateService.getFilingCabinetScheme,
    notTemplateFilterKeys: notTemplateFilterKeysCards,
  },
  StoplistTemplates: {
    loadScheme: stoplistTemplateService.getScheme,
    notTemplateFilterKeys: notTemplateFilterKeysStoplist,
  },
};
