import { CallAPIType, METHODS } from '@/types';
import rootApiService from './root.api.service';
import { IFilingCabinetScheme } from '@/types/cardTemplate';

class CardTemplateService {
  getFilingCabinetScheme = async () => {
    const APIOptions: CallAPIType = {
      path: 'card-templates/scheme',
      method: METHODS.GET,
    };

    const res = await rootApiService.callAPI<IFilingCabinetScheme>(APIOptions);

    return res;
  };
}

export const cardTemplateService = new CardTemplateService();
